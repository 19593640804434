<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />

		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									{{ mainData.yedekParcaKodu }}
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="YEDEK PARÇA" entityName="bm_yedekparca"></EntityHeader>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="yedekParcaKodu">Yedek Parça Kodu <span style="color:red">*</span></label>
								<InputText id="yedekParcaKodu" type="text" v-model="mainData.yedekParcaKodu" />
								<span v-if="v$.mainData.yedekParcaKodu.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.yedekParcaKodu.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="yedekParcaAdi">Yedek Parça Adı <span style="color:red">*</span></label>
								<InputText id="yedekParcaAdi" type="text" v-model="mainData.yedekParcaAdi" />
								<span v-if="v$.mainData.yedekParcaAdi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.yedekParcaAdi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="yedekParcaAdiEn">Yedek Parça Adı (En)</label>
								<InputText id="yedekParcaAdiEn" type="text" v-model="mainData.yedekParcaAdiEn" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="urunTipi">Ürün Tipi</label>
								<Dropdown id="urunTipi" v-model="mainData.urunTipi" :options="SM_urunTipi" optionLabel="Value" optionValue="AttributeValue" placeholder="Ürün Tipi" :showClear="true" />
							</div>
						</div>
						
						<div class="col-3">
							<div class="field p-fluid">
								<label for="varsayilanFiyat">Varsayılan Fiyat</label>
								<InputNumber id="varsayilanFiyat" v-model="mainData.varsayilanFiyat" mode="decimal" locale="tr-TR" :minFractionDigits="2" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="paraBirimiYedekParca" v-model="mainData.paraBirimiYedekParcaName" ref="entity_paraBirimiYedekParca"
									label="Para Birimi" entityName="transactioncurrency" nameField="currencyname" :state="true"
									@itemSelected="paraBirimiYedekParcaSelected = $event" @itemCleared="paraBirimiYedekParcaSelected = null">
								</EntityLookup>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="uretici">Üretici</label>
								<InputText id="uretici" type="text" v-model="mainData.uretici" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="urunTuru">Ürün Türü</label>
								<Dropdown id="urunTuru" v-model="mainData.urunTuru" :options="SM_urunTuru" optionLabel="Value" optionValue="AttributeValue" placeholder="Ürün Türü" :showClear="true" />
							</div>
						</div>

						<div class="col-3">
							<div class="field p-fluid">
								<label for="kategori">Kategori</label>
								<Dropdown id="kategori" v-model="mainData.kategori" :options="SM_kategori" optionLabel="Value" optionValue="AttributeValue" placeholder="Kategori" :showClear="true" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="yedekParcaDurumu">Yedek Parça Durumu</label>
								<Dropdown id="yedekParcaDurumu" v-model="mainData.yedekParcaDurumu" :options="SM_yedekParcaDurumu" optionLabel="Value" optionValue="AttributeValue" placeholder="Yedek Parça Durumu" :showClear="true" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bulunduguYer">Bulunduğu Yer</label>
								<InputText id="bulunduguYer" type="text" v-model="mainData.bulunduguYer" />
							</div>
						</div>
						<div class="col-12">
							<div class="field p-fluid">
								<label for="firmaadi">Açıklama</label>
								<Textarea id="firmaadi" rows="3" :autoResize="false" v-model="mainData.aciklama" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 10010,
			mainData: {},

			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(); },
				}
			],
		}
	},
	async created() {
		this.crmService = new CrmService();
		window.addEventListener('scroll', this.handleScroll);
    },
	computed: {
		profileData(){
			return this.$store.getters.getProfile;
		},
		SM_urunTipi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_uruntipi');
		},
		SM_urunTuru: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_urunturu');
		},
		SM_kategori: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_kategori');
		},
		SM_yedekParcaDurumu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_yedekparcadurumu');
		},
	
		paraBirimiYedekParcaSelected: {
			get: function () {
				if (this.mainData["paraBirimiYedekParcaId"]) {
					return { "Value": this.mainData["paraBirimiYedekParcaId"], "Name": this.mainData["paraBirimiYedekParcaName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["paraBirimiYedekParcaId"] = null;
					this.mainData["paraBirimiYedekParcaName"] = null;
				}
				else {
					this.mainData["paraBirimiYedekParcaId"] = newValue.Value;
					this.mainData["paraBirimiYedekParcaName"] = newValue.Name;
				}
			}
		},
	},
	methods: {
		async OnSave() {
			debugger;

			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					
					const response = await this.crmService.getYedekParcaCreate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							
							setTimeout(() => {
								this.$router.push({ name: 'bm_yedekparca', params: { id: response.olusturulanId } });
							}, 2000);
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == 'Yedek Parçalar');
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				yedekParcaKodu: {
					required : helpers.withMessage('Yedek Parça Kodu alanı dolu olmalıdır!', required),
				},
				yedekParcaAdi: {
					required : helpers.withMessage('Yedek Parça Adı alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
